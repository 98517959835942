import React, { useState, createContext, useContext } from 'react';

const FlowContext = createContext({});

export function FlowProvider({ children }) {
  const [flow, setFlow] = useState();

  return <FlowContext.Provider value={{ flow, setFlow }}>{children}</FlowContext.Provider>;
}
export function useFlow() {
  const context = useContext(FlowContext);

  return context;
}
