/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';

import './src/style/global.css';
import './node_modules/bootstrap/dist/css/bootstrap.min.css';
import './node_modules/slick-carousel/slick/slick.css';
import './node_modules/slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import { FlowProvider } from './src/hooks/flowIndustry';
import { SSRProvider } from 'react-bootstrap';

export const wrapRootElement = ({ element }) => (
  <SSRProvider>
    <FlowProvider>{element}</FlowProvider>
  </SSRProvider>
);
