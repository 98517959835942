exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aviso-legal-jsx": () => import("./../../../src/pages/aviso-legal.jsx" /* webpackChunkName: "component---src-pages-aviso-legal-jsx" */),
  "component---src-pages-consultoria-financeira-jsx": () => import("./../../../src/pages/consultoria-financeira.jsx" /* webpackChunkName: "component---src-pages-consultoria-financeira-jsx" */),
  "component---src-pages-contato-jsx": () => import("./../../../src/pages/contato.jsx" /* webpackChunkName: "component---src-pages-contato-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-escritorio-virtual-jsx": () => import("./../../../src/pages/escritorio-virtual.jsx" /* webpackChunkName: "component---src-pages-escritorio-virtual-jsx" */),
  "component---src-pages-esg-jsx": () => import("./../../../src/pages/esg.jsx" /* webpackChunkName: "component---src-pages-esg-jsx" */),
  "component---src-pages-etica-jsx": () => import("./../../../src/pages/etica.jsx" /* webpackChunkName: "component---src-pages-etica-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-parceiros-jsx": () => import("./../../../src/pages/parceiros.jsx" /* webpackChunkName: "component---src-pages-parceiros-jsx" */),
  "component---src-pages-privacidade-jsx": () => import("./../../../src/pages/privacidade.jsx" /* webpackChunkName: "component---src-pages-privacidade-jsx" */),
  "component---src-pages-qrcode-jsx": () => import("./../../../src/pages/qrcode.jsx" /* webpackChunkName: "component---src-pages-qrcode-jsx" */),
  "component---src-pages-servicos-jsx": () => import("./../../../src/pages/servicos.jsx" /* webpackChunkName: "component---src-pages-servicos-jsx" */),
  "component---src-pages-talentos-jsx": () => import("./../../../src/pages/talentos.jsx" /* webpackChunkName: "component---src-pages-talentos-jsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

